export default function (fetchData) {
  return {
    getUserProfile() {
      return fetchData.get("cloud-sessions/rest/profiles/current");
    },
    setUserReadInstruction() {
      return fetchData.post(
        "cloud-sessions/rest/profiles/current/instruction-reads"
      );
    },
  };
}
