<template>
  <kinesis-container>
    <section class="download download-header">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="download-title text-md-center">
              <span>Скачай Parsec,</span>
              Запустите ваше игровое облако <br />
              И наслаждайтесь играми
            </div>
          </div>

          <div class="col-12 text-center">
            <div class="download__auto-btn">
              <transparent-button :href="links[os]" target="_blank">
                Скачать для {{ os }}</transparent-button
              >
            </div>
          </div>

          <div class="col-12 text-center">
            <div class="download__how-to">
              <router-link to="/instruction"
                >Как начать играть на GATOGA</router-link
              >
            </div>
          </div>
        </div>
      </div>
      <div class="download-figures">
        <figures-parallax></figures-parallax>
      </div>
      <div class="download-blick">
        <img
          class="d-none d-md-inline-block"
          src="@/assets/img/download/blick.svg"
          alt=""
        />
        <img
          class="d-md-none d-inline-block"
          src="@/assets/img/download/blick-header-m.svg"
          alt=""
        />
      </div>
      <div class="download-parallax">
        <kinesis-element :strength="20" class="parallax blick-1">
          <img src="@/assets/img/download/parallax/blick-1.svg" alt="" />
        </kinesis-element>
        <kinesis-element :strength="-20" class="parallax blick-2">
          <img src="@/assets/img/download/parallax/blick-2.svg" alt="" />
        </kinesis-element>
        <kinesis-element :strength="10" class="parallax blick-3">
          <img src="@/assets/img/download/parallax/blick-3.svg" alt="" />
        </kinesis-element>
        <kinesis-element :strength="-10" class="parallax blick-4">
          <img src="@/assets/img/download/parallax/blick-4.svg" alt="" />
        </kinesis-element>
        <kinesis-element :strength="5" class="parallax blick-5">
          <img src="@/assets/img/download/parallax/blick-5.svg" alt="" />
        </kinesis-element>
        <kinesis-element :strength="-20" class="parallax blick-6">
          <img src="@/assets/img/download/parallax/blick-6.svg" alt="" />
        </kinesis-element>
        <kinesis-element :strength="30" class="parallax blick-7">
          <img src="@/assets/img/download/parallax/blick-7.svg" alt="" />
        </kinesis-element>
      </div>
    </section>

    <section class="download download-parsec">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="download__block">
              <div class="download__block-title text-center">
                Выберите вашу операционную систему для&nbsp;загрузки Parsec
              </div>
              <ul
                class="row nav nav-tabs justify-content-md-between"
                id="parsecTab"
                role="tablist"
              >
                <li
                  class="nav-item download__block-item col-md-2 col-6"
                  role="presentation"
                >
                  <button
                    class="nav-link active"
                    id="win-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#win"
                    type="button"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    <img src="@/assets/img/download/windows.png" alt="" />
                    Windows
                    <span>Windows 7+</span>
                  </button>
                </li>
                <li
                  class="nav-item download__block-item col-md-2 col-6"
                  role="presentation"
                >
                  <button
                    class="nav-link"
                    id="chrome-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#chrome"
                    type="button"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    <img src="@/assets/img/download/chrome.png" alt="" />
                    Web
                    <span>Google Chrome</span>
                  </button>
                </li>
                <li
                  class="nav-item download__block-item col-md-2 col-6"
                  role="presentation"
                >
                  <button
                    class="nav-link"
                    id="mac-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#mac"
                    type="button"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    <img src="@/assets/img/download/apple.png" alt="" />
                    MacOS
                    <span
                      >macOS 10.11+ <br />
                      (Metal only)</span
                    >
                  </button>
                </li>
                <li
                  class="nav-item download__block-item col-md-2 col-6"
                  role="presentation"
                >
                  <button
                    class="nav-link"
                    id="android-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#android"
                    type="button"
                    role="tab"
                    aria-controls="contact"
                    aria-selected="false"
                  >
                    <img src="@/assets/img/download/android.png" alt="" />
                    Android
                    <span> Google Play </span>
                  </button>
                </li>
                <li
                  class="nav-item download__block-item col-md-2 col-6"
                  role="presentation"
                >
                  <button
                    class="nav-link"
                    id="pi-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#pi"
                    type="button"
                    role="tab"
                    aria-controls="contact"
                    aria-selected="false"
                  >
                    <img src="@/assets/img/download/pi.png" alt="" />
                    Raspberry&nbsp;Pi&nbsp;3
                    <span>Raspbian</span>
                  </button>
                </li>
                <li
                  class="nav-item download__block-item col-md-2 col-6"
                  role="presentation"
                >
                  <button
                    class="nav-link"
                    id="linux-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#linux"
                    type="button"
                    role="tab"
                    aria-controls="contact"
                    aria-selected="false"
                  >
                    <img src="@/assets/img/download/linux.png" alt="" />
                    Linux
                    <span
                      >Ubuntu 18.04 <br />
                      LTS Desktop</span
                    >
                  </button>
                </li>
              </ul>
              <div
                class="tab-content download__block-content"
                id="parsecTabContent"
              >
                <div
                  class="tab-pane fade show active"
                  id="win"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <transparent-button
                    href="/downloads/parsec-windows.exe"
                    width="365"
                    target="_blank"
                    >Скачать для Windows x64</transparent-button
                  >
                  <transparent-button
                    href="/downloads/parsec-windows32.exe"
                    width="137"
                    target="_blank"
                    >x32</transparent-button
                  >
                </div>
                <div
                  class="tab-pane fade"
                  id="chrome"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <transparent-button
                    href="https://web.parsec.app/"
                    target="_blank"
                    >Открыть в браузере</transparent-button
                  >
                </div>
                <div
                  class="tab-pane fade"
                  id="mac"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <transparent-button
                    href="/downloads/parsec-macos.pkg"
                    target="_blank"
                    >Скачать для MacOS</transparent-button
                  >
                </div>
                <div
                  class="tab-pane fade"
                  id="android"
                  role="tabpanel"
                  aria-labelledby="contact-tab"
                >
                  <transparent-button
                    href="https://play.google.com/store/apps/details?id=tv.parsec.client"
                    target="_blank"
                    >Скачать для Android</transparent-button
                  >
                </div>
                <div
                  class="tab-pane fade"
                  id="pi"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <transparent-button
                    href="/downloads/parsec-rpi.deb"
                    target="_blank"
                    width="370"
                    >Скачать для Raspberry Pi 3</transparent-button
                  >
                </div>
                <div
                  class="tab-pane fade"
                  id="linux"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <transparent-button
                    href="/downloads/parsec-linux.deb"
                    target="_blank"
                    >Скачать для Linux</transparent-button
                  >
                </div>
              </div>

              <div class="download__annotation text-center">
                Используете компьютер без прав администратора? <br />
                Скачайте портативную версию
                <a href="/downloads/parsec-flat-windows.zip">Parsec</a>,
                не&nbsp;требующую установки.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="download download-gatoga">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="download-title text-md-center">
              Воспользуйтесь
              <span>ALPHA версией лаунчера GATOGA</span>
            </div>
          </div>
          <div class="col-12">
            <div class="download__block">
              <ul
                class="row nav nav-tabs justify-content-center"
                id="gatogaLauncher"
                role="tablist"
              >
                <li
                  class="nav-item download__block-item col-auto"
                  role="presentation"
                >
                  <button
                    class="nav-link active"
                    id="gatogaWin-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#gatogaWin"
                    type="button"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    <img src="@/assets/img/download/windows.png" alt="" />
                    Windows
                    <span>Windows 7+</span>
                  </button>
                </li>
              </ul>
              <div
                class="tab-content download__block-content"
                id="gatogaLauncherContent"
              >
                <div
                  class="tab-pane fade show active"
                  id="gatogaWin"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <transparent-button
                    href="/downloads/gatoga-launcher.exe"
                    target="_blank"
                    >Скачать для Windows</transparent-button
                  >
                </div>
              </div>

              <div class="download__annotation text-center">
                <span>Уважаемые игроки!</span>
                Это первая версия нашего лаунчера, которая содержит функции
                настроек качества и микрофона. <br />
                Просим не быть строгими, до последующих обновлений. <br />
                Если по каким-то причинам, через наш лаунчер вы потерпели
                неудачу, напишите в ТП и воспользуйтесь Parsec.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="download-blick-footer">
        <img
          class="d-none d-md-inline-block"
          src="@/assets/img/download/blick-footer.png"
          alt=""
        />
        <img
          class="d-md-none d-inline-block"
          src="@/assets/img/download/blick-footer-m.svg"
          alt=""
        />
      </div>
    </section>
  </kinesis-container>
</template>

<script>
import FiguresParallax from "@/components/FiguresParallax";
import TransparentButton from "@/components/ui/TransparentButton";

export default {
  name: "DownloadView",
  data() {
    return {
      links: {
        Android:
          "https://play.google.com/store/apps/details?id=tv.parsec.client",
        Windows: "/downloads/parsec-windows.exe",
        MacOS: "/downloads/parsec-macos.pkg",
      },
      os: "",
    };
  },
  components: {
    FiguresParallax,
    TransparentButton,
  },
  mounted() {
    this.os = this.getOS();
  },
  methods: {
    getOS() {
      if (navigator.appVersion.indexOf("Windows") >= 0) return "Windows";
      if (navigator.appVersion.indexOf("Android") >= 0) return "Android";
      if (navigator.appVersion.indexOf("Macintosh") >= 0) return "MacOS";
      return "Windows";
    },
  },
};
</script>

<style lang="sass" scoped>
.download
  position: relative
  padding-bottom: 165px
  @media(max-width: $tablet)
    padding-bottom: 80px
  @media(max-width: $mobile)
    padding-bottom: 115px
  .container
    max-width: 1300px
  &-figures
    position: absolute
    width: 1030px
    height: 600px
    right: -100px
    top: -200px
    left: unset
    pointer-events: none
    @media(max-width: $mobile)
      right: 0
      top: 60px
  &-blick
    position: absolute
    left: 0
    right: 0
    top: -100px
    margin: auto
    pointer-events: none
    z-index: -1
    @media(max-width: $mobile)
      img
        max-width: 100%
        width: 100%
  &-parallax
    .parallax
      position: absolute
      z-index: -1
      @media(max-width: $computer)
        display: none
    .blick-1
      top: 80px
      left: 545px
    .blick-2
      top: 370px
      left: 530px
    .blick-3
      top: 450px
      left: 375px
    .blick-4
      top: 510px
      left: 560px
    .blick-5
      top: 90px
      right: 515px
    .blick-6
      top: 385px
      right: 385px
    .blick-7
      top: 415px
      right: 575px
  &-title
    font-size: $big-title-size
    font-weight: 800
    line-height: 85px
    margin-top: 40px
    margin-bottom: 67px
    @media(max-width: $computer)
      font-size: 50px
      line-height: 56px
    @media(max-width: $tablet)
      font-size: 36px
      line-height: 42px
      margin-bottom: 40px
    @media(max-width: $mobile)
      margin-bottom: 60px
    span
      color: $light-blue
      display: block
  &-separator
    width: 2px
    height: 93%
    background: $blue
    border-radius: 13px
    @media(max-width: $tablet)
      height: 85%
    @media(max-width: $mobile)
      display: none
  &__annotation
    font-size: 16px
    line-height: 22px
    font-weight: 400
    @media(max-width: $tablet)
      font-size: 14px
      line-height: 20px
    a
      text-decoration: underline
      &:hover
        text-decoration: none
    span
      font-weight: 700
      display: block
  &-blick-footer
    position: absolute
    left: 0
    right: 0
    bottom: 0
    margin: 0 auto
    z-index: -1
    img
      width: 100%
  &__how-to
    margin-top: 30px
    a
      text-decoration: underline
      &:hover
        text-decoration: none
  &__block
    @media(max-width: $mobile)
      margin-bottom: 50px
    &-title
      font-size: $small-title-size
      font-weight: 800
      margin-bottom: 55px
      @media(max-width: $computer) and (min-width: $laptop)
        font-size: 22px
        line-height: 28px
      @media(max-width: $laptop)
        font-size: 18px
        line-height: 24px
      @media(max-width: $tablet)
        font-size: 20px
        line-height: 26px
        margin-bottom: 60px
      @media(max-width: $mobile)
        font-size: 21px
        line-height: 25px
        margin-bottom: 50px
    ul
      border-bottom: 0
      margin-bottom: 65px
      @media(max-width: $mobile)
        margin-bottom: 30px
    &-item
      .nav-link
        background-color: unset
        padding: 0
        border: 0
        font-size: $regular-size
        font-weight: 700
        max-width: 100px
        color: #fff
        @media(max-width: $laptop)
          max-width: 80px
          font-size: 18px
          line-height: 24px
        @media(max-width: $tablet)
          max-width: 70px
          font-size: 16px
          line-height: 22px
        @media(max-width: $mobile)
          max-width: 64px
          font-size: 14px
          line-height: 20px
        img
          max-width: 108px
          margin-bottom: 15px
          filter: brightness(0.5)
          will-change: filter
          transition: filter .3s
        span
          display: inline-block
          padding-top: 5px
          font-size: 16px
          font-weight: 400
          color: #fff
      .nav-link.active
        background-color: unset
        color: $light-blue
        font-weight: 700
        img
          filter: brightness(1) drop-shadow(0 0 30px #2750B9)
        span
          font-size: 16px
          font-weight: 400
          color: #fff
    &-content
      & > .active
        display: flex
        justify-content: space-between
      .gradient-button-wrapper
        background: $green
        display: inline-block
        border-radius: 9px
        padding: 3px
        height: 59px
        align-items: center
        @media(max-width: $computer)
          height: 55px
        @media(max-width: $tablet)
          height: 40px
          padding: 2px
.download-header
  padding-top: 120px
  overflow: hidden
  @media(max-width: $tablet)
    padding-top: 120px
    padding-bottom: 170px
  @media(max-width: $mobile)
    padding-top: 90px
.download-parsec, .download-gatoga
  padding: 120px 0
  background: #101623
  @media(max-width: $tablet)
    padding: 105px 0
  @media(max-width: $mobile)
    padding: 60px 0 40px
  ul
    margin-bottom: 70px
    @media(max-width: $mobile)
      justify-content: space-between
      margin-right: 0
    li
      width: 140px
      max-width: 140px
      justify-content: center
      @media(max-width: $mobile)
        margin-bottom: 15px
      .nav-link
        max-width: 140px
        img
          margin-bottom: 30px
    li + li
      @media(max-width: $mobile)
        margin-left: 0
  .download__block-content
    margin-bottom: 30px
    & > .active
      justify-content: center
      a + a
        margin-left: 15px
.download-gatoga
  padding: 130px 0
  background: unset
  @media(max-width: $mobile)
    padding: 100px 0
</style>
